import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Loader from '../../components/Loader/Loader';
import HiroApi from '../../HiroApi';
import { selectCurrentFacility, showFacilitySelect } from '../../state/systemSlice';
import Discharges from './Discharges';
import PastPatientAppointments from './PastPatientAppointments';
import PatientAppointments from './PatientAppointments';

export default function PatientProfile({ patientId: propPatientId }) {
  const location = useLocation();
  const { getAccessTokenSilently } = useAuth0();
  const facility = useSelector(selectCurrentFacility);
  const showFacilitySelectModal = useSelector(showFacilitySelect);
  const [loading, setLoading] = useState(true);
  const [patient, setPatient] = useState({});
  const patientId = propPatientId || location.pathname.split('/').pop();

  useEffect(() => {
    if (!isEmpty(facility) && !showFacilitySelectModal) {
      fetchPatient();
    }
  }, []);

  const fetchPatient = async () => {
    try {
      const token = await getAccessTokenSilently();
      const patient = await HiroApi.getPatient(patientId, token);

      setPatient(patient);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <div className={`patient-profile ${propPatientId ? 'modal' : ''}`}>
      <div className="section">
        <h3>Patient Information</h3>

        <div className="info">
          <TextField label="Last Name" size="small" value={patient.lastName} />
          <TextField label="First Name" size="small" value={patient.firstName} />
          <TextField
            label="Date of Birth"
            size="small"
            value={patient.dob}
            InputProps={{
              endAdornment: patient.isMinor ? (
                <InputAdornment position="start">
                  <Tooltip
                    title={
                      <div
                        style={{
                          whiteSpace: 'pre-line',
                          fontSize: '14px',
                        }}
                      >
                        Patient is a minor
                      </div>
                    }
                    placement="bottom"
                    arrow
                  >
                    <InfoOutlinedIcon sx={{ color: 'orange' }} />
                  </Tooltip>
                </InputAdornment>
              ) : null,
            }}
          />
          <TextField label="EHR ID" size="small" value={patient.ehrId} />
        </div>
      </div>

      <Discharges discharges={patient.discharges} />

      <PastPatientAppointments id={patientId} type="patient" />

      <PatientAppointments id={patientId} type="patient" />
    </div>
  );
}
