import './style.scss';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';

export default function ConfirmationModal({ confirmedAction, message, open, setOpen }) {
  const handleConfirm = () => {
    setOpen(false);
    confirmedAction();
  };

  const handleClose = () => setOpen(false);

  return (
    <Dialog className="confirmation" open={open} onClose={handleClose}>
      <DialogTitle>Confirm Action</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className="cancel" onClick={handleClose}>
          Cancel
        </Button>
        <Button className="confirm" onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
