import { isEmpty } from 'lodash';
import React from 'react';

export default function DischargeDetails({ campaign }) {
  const { comments, diagnosis, payers, pcp } = campaign;

  return (
    <div className="discharge-details">
      <div className="pcp">
        <h3>Listed PCP</h3>

        <div className="info-table">
          <table>
            <tbody>
              <tr>
                <td>
                  <span>{pcp ? pcp.name : 'N/A'}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="diagnoses">
        <h3>Diagnoses</h3>

        <div className="info-table">
          <table>
            <tbody>
              {isEmpty(diagnosis) ? (
                <tr>
                  <td>No diagnoses available.</td>
                </tr>
              ) : (
                diagnosis.split('\n').map((diagnosis) => (
                  <tr key={diagnosis}>
                    <td>{diagnosis}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="payers">
        <h3>Insurance</h3>

        <div className="info-table">
          <table>
            {!isEmpty(payers) && (
              <thead>
                <tr>
                  <th>Payer</th>
                  <th>Member ID</th>
                </tr>
              </thead>
            )}
            <tbody>
              {isEmpty(payers) ? (
                <tr>
                  <td>No insurance information available.</td>
                </tr>
              ) : (
                payers.map((payer) => (
                  <tr key={payer.name}>
                    <td>{payer.name}</td>
                    <td>{payer.memberId}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="comments">
        <h3>Comments</h3>

        <div className="info-table">
          <table>
            <tbody>
              {isEmpty(comments) ? (
                <tr>
                  <td>No comments available.</td>
                </tr>
              ) : (
                comments.split('\n').map((comment) => (
                  <tr key={comment}>
                    <td>{comment}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
