import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_API_AUDIENCE,
  REACT_APP_HOST,
  COHERENCE_ENVIRONMENT_DOMAIN,
} = process.env;

function Auth0ProviderWithHistory({ children }) {
  const navigate = useNavigate();
  const appDomain = COHERENCE_ENVIRONMENT_DOMAIN || REACT_APP_HOST;

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || '/dashboard');
  };

  return (
    <Auth0Provider
      scope="read:current_user"
      audience={REACT_APP_API_AUDIENCE}
      domain={REACT_APP_AUTH0_DOMAIN}
      clientId={REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      useRefreshTokens
      useRefreshTokensFallback
    >
      {children}
    </Auth0Provider>
  );
}

export default Auth0ProviderWithHistory;
