import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import { debounce, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  selectCurrentFacility,
  selectProviders,
  selectSpecialties,
  selectUploadFileFilters,
} from '../../state/systemSlice';
import { DISQUALIFIERS_ALL_REFERRALS, DISQUALIFIERS_SINGLE_REFERRAL } from '../../utils/constants/disqualifiers';
import { DEBOUNCE_TIME, UNASSIGNED_PROVIDER_ID } from '../../utils/constants/system';
import { QUEUE_STATUS } from '../../utils/constants/upload';
import { findSpecialtyIdByName, getProviderInfo, getProviderName, getSpecialtyInfo } from '../../utils/helpers';
import Discharges from '../PatientProfile/Discharges';
import PastPatientAppointments from '../PatientProfile/PastPatientAppointments';
import PatientAppointments from '../PatientProfile/PatientAppointments';
import CampaignTypeToggle from './CampaignTypeToggle';
import ExtractionRecommendation from './ExtractionRecommendation';
import { PatientDob } from './PatientDob';

export default function CollapsibleRow(props) {
  const { ingestionId, isChecked, openPatientModal, patient, patients, setPatients, selectPatient, selectedPatients } =
    props;
  const facility = useSelector(selectCurrentFacility);
  const providers = useSelector(selectProviders);
  const specialties = useSelector(selectSpecialties);
  const filters = useSelector(selectUploadFileFilters);
  const [open, setOpen] = useState(false);
  const [notes, setNotes] = useState('');

  useEffect(() => {
    if (!isEmpty(patient.notes)) {
      setNotes(patient.notes);
    }
  }, []);

  const updateNote = (event) => {
    const updatedNote = event.target.value;
    const rowsCopy = patients[patient.queue].slice();
    const index = rowsCopy.findIndex((row) => row.id === patient.id);
    const editedRow = rowsCopy[index];

    editedRow.notes = updatedNote;
    rowsCopy[index] = editedRow;
    setNotes(event.target.value);
    setPatients({ ...patients, [patient.queue]: rowsCopy });
    debouncedUpdate(ingestionId, patient.id, { notes: event.target.value });
  };

  const debouncedUpdate = useCallback(
    debounce((ingestionId, patientId, patient) => props.updateField(ingestionId, patientId, patient), DEBOUNCE_TIME),
    []
  );

  const editCell = (event) => {
    const { id, key } = event.target.dataset;
    const rowsCopy = patients[patient.queue].slice();
    const index = rowsCopy.findIndex((row) => row.id === id);
    const editedRow = rowsCopy[index];
    const newValue = event.target.value;

    editedRow[key] = newValue;
    rowsCopy[index] = editedRow;
    setPatients({ ...patients, [patient.queue]: rowsCopy });
    debouncedUpdate(ingestionId, patient.id, { [key]: newValue });
  };

  const addProviderFromIngestion = (provider) => {
    const providerCopy = providers.slice();

    providerCopy.unshift({ id: 'temp', displayName: provider });

    return providerCopy;
  };

  const handleProviderChange = (event, id, providersIndex) => {
    const newProviderId = event.target.value;
    const updatedRows = patients[patient.queue].map((row) => ({
      ...row,
      referrals: row.referrals.map((referral) => ({ ...referral })),
    }));
    const rowIndex = updatedRows.findIndex((row) => row.id === id);

    if (newProviderId === 'temp') {
      delete updatedRows[rowIndex].referrals[providersIndex].providerId;
      delete updatedRows[rowIndex].referrals[providersIndex].followupSpecialtyId;
    } else {
      const provider = getProviderInfo(newProviderId);
      const specialty = provider.specialties[0];
      const specialtyId = findSpecialtyIdByName(specialty);

      updatedRows[rowIndex].referrals[providersIndex].providerId = newProviderId;

      if (provider.id === UNASSIGNED_PROVIDER_ID) {
        const pcpSpecialtyId = findSpecialtyIdByName('Primary Care');

        updatedRows[rowIndex].referrals[providersIndex].followupSpecialtyId = pcpSpecialtyId;
      } else {
        updatedRows[rowIndex].referrals[providersIndex].followupSpecialtyId = specialtyId;
      }

      if (!selectedPatients.some((selectedPatient) => selectedPatient.id === patient.id)) {
        const updatedPatient = updatedRows[rowIndex];
        selectPatient(updatedPatient, selectedPatients);
      }
    }

    setPatients({ ...patients, [patient.queue]: updatedRows });
    props.updateField(ingestionId, id, { referrals: updatedRows[rowIndex].referrals });
  };

  const handleSpecialtyChange = (event, id, providersIndex) => {
    const newSpecialtyId = event.target.value;
    const rows = patients[patient.queue].slice();
    const rowIndex = patients[patient.queue].findIndex((row) => row.id === id);

    if (newSpecialtyId === null) {
      delete rows[rowIndex].referrals[providersIndex].followupSpecialtyId;
    } else {
      rows[rowIndex].referrals[providersIndex].followupSpecialtyId = newSpecialtyId;
    }

    setPatients({ ...patients, [patient.queue]: rows });
    props.updateField(ingestionId, id, { referrals: rows[rowIndex].referrals });
  };

  const handleDisqualifierChange = (event, child, index) => {
    // MUI component returns an array of selections with the latest at the end
    const selected = event.target.value;
    const { category, value } = child.props;

    if (category === 'All') {
      updateAllReferrals(selected, value);
    }

    if (category === 'Single') {
      updateSingleReferral(selected, index);
    }
  };

  const updateAllReferrals = (selectedOptions, selectedValue) => {
    const newSelection = selectedOptions[selectedOptions.length - 1];
    const queue = patients[patient.queue].slice();
    const patientIndex = queue.findIndex((row) => row.id === patient.id);
    const patientToUpdate = { ...queue[patientIndex] };

    // when removing a disqualifier, selectedOptions is passed an array without that selectedValue
    // if selectedValue is not in selectedOptions, we know to remove it
    if (selectedOptions.includes(selectedValue)) {
      patientToUpdate.referrals.forEach((referral) => {
        if (!referral.disqualifiers.includes(newSelection)) {
          referral.disqualifiers.push(newSelection);
        }
      });
    } else {
      patientToUpdate.referrals.forEach((referral) => {
        referral.disqualifiers = referral.disqualifiers.filter((disqualifier) => disqualifier !== selectedValue);
      });
    }

    setPatients({ ...patients, [patient.queue]: queue });
    props.updateField(ingestionId, patient.id, { referrals: queue[patientIndex].referrals });
  };

  const updateSingleReferral = (selected, referralIndex) => {
    const queue = patients[patient.queue].slice();
    const patientIndex = queue.findIndex((row) => row.id === patient.id);
    const patientToUpdate = { ...queue[patientIndex] };
    const referralToUpdate = patientToUpdate.referrals[referralIndex];

    referralToUpdate.disqualifiers = selected;
    setPatients({ ...patients, [patient.queue]: queue });
    props.updateField(ingestionId, patient.id, { referrals: queue[patientIndex].referrals });
  };

  const isProviderRequired = (providerId) => {
    return providerId ? '' : 'required';
  };

  const isSpecialtyRequired = (specialty) => {
    return specialty ? '' : 'required';
  };

  const isPhoneNumberRequired = (phoneNumber) => {
    return isEmpty(phoneNumber) ? 'required' : '';
  };

  const getSelectedNumber = (phoneNumber) => {
    const number = patient.phoneNumbers.find(
      (number) => number.phoneNumber === phoneNumber.phoneNumber && number.source === phoneNumber.source
    );

    return number || '';
  };

  const handlePhoneChange = (event) => {
    const selectedNumber = event.target.value;
    const rows = patients[patient.queue].slice();
    const rowIndex = patients[patient.queue].findIndex((row) => row.id === patient.id);

    rows[rowIndex].campaignPhoneNumber = selectedNumber;

    setPatients({ ...patients, [patient.queue]: rows });
    props.updateField(ingestionId, patient.id, { campaignPhoneNumber: selectedNumber });
  };

  const highlightRow = () => {
    if (patient.hasActiveCampaign) {
      return 'red-background';
    }

    if (patient.hasPastCampaign) {
      return 'orange-background';
    }

    if (patient.hasPastDischarge) {
      return 'yellow-background';
    }

    return null;
  };

  const showPhoneNumbersWarning = () => {
    const hasAnyDuplicateOrHistorical = patient.phoneNumbers.some(
      (phoneNumber) => phoneNumber.hasDuplicatePhoneNumber || phoneNumber.hasHistoricalCampaign
    );

    if (hasAnyDuplicateOrHistorical) {
      return true;
    }

    return false;
  };

  const phoneNumberStyle = (phone) => {
    const { wrongNumber, optOut, hasDuplicatePhoneNumber, hasHistoricalCampaign } = phone;

    if (optOut) {
      return 'red';
    }

    if (wrongNumber) {
      return 'orange';
    }

    if (hasDuplicatePhoneNumber) {
      return 'gold';
    }

    if (hasHistoricalCampaign) {
      return 'purple';
    }

    return null;
  };

  const handleCampaignTypeChange = (newType) => {
    if (newType !== null && patient.queue !== QUEUE_STATUS.SENT && patient.queue !== QUEUE_STATUS.UNFIT) {
      const rows = patients[patient.queue].slice();
      const rowIndex = patients[patient.queue].findIndex((row) => row.id === patient.id);

      rows[rowIndex].campaignType = newType;
      setPatients({ ...patients, [patient.queue]: rows });
      props.updateField(ingestionId, patient.id, { campaignType: newType });
    }
  };

  const renderDisqualifierValue = (selected) => {
    if (selected.length > 1) {
      return `Disqualifiers (${selected.length})`;
    }
    return selected.join(', ');
  };

  const formatDateTime = (timestamp) => {
    return dayjs(timestamp).tz(facility.timezone).format('M/D/YY h:mma');
  };

  const formatNotesTooltip = (notes) => {
    return `Discharge Date: ${formatDateTime(patient.dateTime)}\n\nNotes: ${notes}`;
  };

  const formatEHRTooltip = (patient) => {
    return `Ingested Name: ${patient.name}\nMatched Name: ${patient.ehrRecord.lastName}, ${patient.ehrRecord.firstName}`;
  };

  const copyPhoneNumber = (event, phoneNumber) => {
    event.stopPropagation();
    navigator.clipboard.writeText(phoneNumber.slice(2));
  };

  const ehrNameMatches = (patient) => {
    if (patient.ehrRecord && patient.ehrRecord.nameMismatch) {
      return 'mismatch';
    }
    return '';
  };

  return (
    <React.Fragment key={patient.id}>
      <TableRow key={patient.id} className={`collapsible-row ${patient.queue}`}>
        <TableCell className={highlightRow()}>
          {patient.queue === 'sent' ? null : (
            <Checkbox
              checked={isChecked(patient.id, selectedPatients)}
              onChange={() => selectPatient(patient, selectedPatients)}
            />
          )}
        </TableCell>
        <TableCell align="center" className={highlightRow()}>
          {patient.queue === 'pending' ? (
            <input
              type="text"
              placeholder="Name"
              data-id={patient.id}
              data-key="name"
              className={`${highlightRow()}-input`}
              onChange={editCell}
              value={patient.name}
            />
          ) : (
            <Link to={`/patients/${patient.patientId}`}>{patient.name}</Link>
          )}
        </TableCell>
        <TableCell align="center" className={highlightRow()}>
          <PatientDob patient={patient} />
        </TableCell>
        <TableCell align="center" className={`phone-numbers ${highlightRow()}`}>
          {patient.queue === 'unfit' || patient.queue === 'sent' ? (
            patient.campaignPhoneNumber.nationalFormat
          ) : (
            <FormControl sx={{ minWidth: 150 }}>
              <Select
                value={getSelectedNumber(patient.campaignPhoneNumber)}
                onChange={handlePhoneChange}
                className={isPhoneNumberRequired(patient.campaignPhoneNumber)}
              >
                {patient.phoneNumbers.map((phone) => (
                  <MenuItem
                    key={`${phone.source} - ${phone.nationalFormat}`}
                    value={phone}
                    className={`number ${phoneNumberStyle(phone)}`}
                    disabled={phone.optOut || phone.wrongNumber}
                  >
                    {phone.source} ({phone.lineType}): {phone.nationalFormat}
                    {phone.hasDuplicatePhoneNumber || phone.hasHistoricalCampaign ? (
                      <IconButton size="small" onClick={(e) => copyPhoneNumber(e, phone.phoneNumber)}>
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    ) : null}
                  </MenuItem>
                ))}
              </Select>
              {showPhoneNumbersWarning() ? <InfoOutlinedIcon sx={{ color: 'orange' }} /> : null}
            </FormControl>
          )}
        </TableCell>
        <TableCell align="center" className={highlightRow()}>
          {patient.queue === 'unfit' || patient.queue === 'sent' ? (
            patient.pcp
          ) : (
            <input
              type="text"
              placeholder="PCP"
              className={`${highlightRow()}-input`}
              data-id={patient.id}
              data-key="pcp"
              onChange={editCell}
              value={patient.pcp}
            />
          )}
        </TableCell>
        <TableCell align="center" className={highlightRow()}>
          {patient.queue === 'unfit' || patient.queue === 'sent'
            ? patient.referrals.map((referral) => (
                <div key={referral.followupProvider}>
                  {getProviderName(referral.providerId, referral.followupProvider)}
                </div>
              ))
            : patient.referrals.map((referral, referralIndex) => {
                return (
                  <Tooltip
                    title={
                      <div
                        style={{
                          whiteSpace: 'pre-line',
                          fontSize: '14px',
                        }}
                      >
                        {referral.followupProvider}
                      </div>
                    }
                    placement="top"
                    arrow
                  >
                    <FormControl sx={{ minWidth: 150 }} className="multi" key={referral.followupProvider}>
                      <Select
                        value={referral?.providerId || 'temp'}
                        onChange={(event) => handleProviderChange(event, patient.id, referralIndex)}
                        className={isProviderRequired(referral?.providerId)}
                      >
                        {addProviderFromIngestion(referral.followupProvider).map((provider) => (
                          <MenuItem key={provider.id} id={provider.id} value={provider.id}>
                            {provider.displayName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Tooltip>
                );
              })}
        </TableCell>
        <TableCell align="center" className={highlightRow()}>
          {patient.queue === 'unfit' || patient.queue === 'sent'
            ? patient.referrals.map((referral) => (
                <div key={referral.followupProvider}>
                  {referral.followupSpecialtyId ? getSpecialtyInfo(referral.followupSpecialtyId)?.name : null}
                </div>
              ))
            : patient.referrals.map((provider, providersIndex) => {
                return (
                  <FormControl sx={{ minWidth: 150 }} className="multi" key={provider.followupProvider}>
                    <Select
                      value={provider.followupSpecialtyId || ''}
                      onChange={(event) => handleSpecialtyChange(event, patient.id, providersIndex)}
                      className={isSpecialtyRequired(provider.followupSpecialtyId)}
                    >
                      <MenuItem value={null}>None</MenuItem>
                      {specialties.map((specialty) => (
                        <MenuItem key={specialty.id} id={specialty.id} value={specialty.id}>
                          {specialty.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                );
              })}
        </TableCell>
        <TableCell align="center" className={highlightRow()}>
          {patient.queue === 'unfit' || patient.queue === 'sent'
            ? patient.referrals.map((referral) => {
                return referral.disqualifiers.map((disqualifier) => <div>{disqualifier}</div>);
              })
            : patient.referrals.map((referral, referralIndex) => {
                return (
                  <FormControl key={referral.id} sx={{ minWidth: 150 }} className="multi">
                    <Select
                      multiple
                      value={referral.disqualifiers || []}
                      onChange={(event, child) => handleDisqualifierChange(event, child, referralIndex)}
                      renderValue={renderDisqualifierValue}
                    >
                      <ListSubheader>All Referrals</ListSubheader>
                      {DISQUALIFIERS_ALL_REFERRALS.map((disqualifier) => (
                        <MenuItem key={disqualifier} value={disqualifier} category="All">
                          {disqualifier}
                        </MenuItem>
                      ))}
                      <ListSubheader>Single Referral</ListSubheader>
                      {DISQUALIFIERS_SINGLE_REFERRAL.map((disqualifier) => (
                        <MenuItem key={disqualifier} value={disqualifier} category="Single">
                          {disqualifier}
                        </MenuItem>
                      ))}
                      <MenuItem value="Other" category="Single">
                        Other
                      </MenuItem>
                    </Select>
                  </FormControl>
                );
              })}
        </TableCell>
        <TableCell align="center" className={`${highlightRow()} ${ehrNameMatches(patient)}`}>
          {ehrNameMatches(patient) ? (
            <Tooltip
              title={
                <div
                  style={{
                    whiteSpace: 'pre-line',
                    fontSize: '14px',
                  }}
                >
                  {formatEHRTooltip(patient)}
                </div>
              }
              placement="bottom"
              arrow
            >
              <div>{patient.ehrRecord?.id}</div>
            </Tooltip>
          ) : (
            patient.ehrRecord?.id
          )}
        </TableCell>
        <TableCell align="center" className={`payer ${highlightRow()}`}>
          {patient?.payers?.map((payer) => (
            <div key={payer.name}>
              {payer.name} ({payer.memberId})
            </div>
          ))}
        </TableCell>
        <TableCell align="center" className={`capitalize ${highlightRow()}`}>
          {filters.queue === 'all' ? (
            patient.queue
          ) : (
            <IconButton onClick={() => openPatientModal(patient.patientId)}>
              <AccountCircleIcon />
            </IconButton>
          )}
        </TableCell>
        <TableCell align="center" className={`campaignType ${highlightRow()}`}>
          <CampaignTypeToggle
            campaignType={patient.campaignType}
            onChange={handleCampaignTypeChange}
            queue={patient.queue}
          />
        </TableCell>
        <TableCell align="center" className={`notes ${highlightRow()}`}>
          <Tooltip
            title={
              <div
                style={{
                  whiteSpace: 'pre-line',
                  fontSize: '14px',
                }}
              >
                {formatNotesTooltip(patient.notes)}
              </div>
            }
            placement="bottom"
            arrow
          >
            <Badge color="warning" variant="dot" invisible={!patient.comments}>
              <TextSnippetOutlinedIcon
                sx={{ color: patient.notes?.length ? 'black' : 'lightgray' }}
                onClick={() => setOpen(!open)}
              />
            </Badge>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow className="collapse">
        <TableCell colSpan={10}>
          <Collapse in={open}>
            <Box sx={{ margin: 1, display: 'flex', gap: 2 }}>
              {patient.extracted && <ExtractionRecommendation extraction={patient.extracted} />}
              <div className="comments-container">
                <TextField
                  label="Comments"
                  multiline
                  rows={4}
                  value={patient.comments || ''}
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{ flex: 1 }}
                />
                <TextField label="Notes" multiline rows={4} value={notes} onChange={updateNote} sx={{ flex: 1 }} />
              </div>
              <div className="patient-details-container">
                {open ? <Discharges patientId={patient.patientId} /> : null}
                {open ? <PastPatientAppointments id={patient.patientId} type="patient" /> : null}
                {open ? <PatientAppointments id={patient.patientId} type="patient" /> : null}
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
