import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TodayIcon from '@mui/icons-material/Today';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Rating from '@mui/material/Rating';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { debounce, findIndex, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import BottomSheet from '../../components/BottomSheet/component';
import Loader from '../../components/Loader/Loader';
import UserAvatar from '../../components/UserAvatar/component';
import UserSelect from '../../components/UserSelect/component';
import HiroApi from '../../HiroApi';
import { selectCurrentFacility, selectProviders } from '../../state/systemSlice';
import { setCurrentCampaignId } from '../../state/twilioSlice';
import { selectUserType } from '../../state/userSlice';
import { DEBOUNCE_TIME, USER_ROLES } from '../../utils/constants/system';
import { formatDatetime, getProviderInfo, getSpecialtyInfo, getUser, mapEngagementStatus } from '../../utils/helpers';
import { assignCampaign } from '../../utils/helpers-api';
import Appointments from '../PatientProfile/Appointments';
import MessageHistory from '../PatientProfile/MessageHistory';
import PastPatientAppointments from '../PatientProfile/PastPatientAppointments';
import PatientAppointments from '../PatientProfile/PatientAppointments';
import ProvidersDrawer from '../PatientProfile/ProvidersDrawer';
import CampaignActions from './CampaignActions';
import CampaignAudit from './CampaignAudit';
import ContactAttempts from './ContactAttempts';
import DischargeDetails from './DischargeDetails';
import PhoneNumbers from './PhoneNumbers';

export default function Campaign() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { getAccessTokenSilently } = useAuth0();
  const facility = useSelector(selectCurrentFacility);
  const providers = useSelector(selectProviders);
  const userType = useSelector(selectUserType);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [ratingLoading, setRatingLoading] = useState(false);
  const [isAuditOpen, setIsAuditOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [patientId, setPatientId] = useState('');
  const [campaign, setCampaign] = useState({
    autopilot: true,
    messages: [],
    contactAttempts: [],
    status: '',
    providers: [],
    referrals: [],
  });

  const campaignId = location.pathname.split('/').pop();

  useEffect(() => {
    dispatch(setCurrentCampaignId(campaignId));

    if (!isEmpty(facility) && !isEmpty(campaignId)) {
      getCampaign();
    }

    return () => {
      dispatch(setCurrentCampaignId(''));
    };
  }, []);

  const getCampaign = async () => {
    try {
      setLoading(true);

      const token = await getAccessTokenSilently();
      const campaign = await HiroApi.getPatientCampaign(campaignId, token);

      setCampaign(campaign);
      setPatientId(campaign.patientId);
    } catch (error) {
      if (error.statusCode === 404) {
        navigate('/404');
      }
    } finally {
      setLoading(false);
    }
  };

  const getCampaignSilently = async () => {
    try {
      const token = await getAccessTokenSilently();
      const campaign = await HiroApi.getPatientCampaign(campaignId, token);

      setCampaign(campaign);
    } catch (error) {
      console.log(error);
    }
  };

  const updateCampaign = async (key, payload) => {
    try {
      const token = await getAccessTokenSilently();

      await HiroApi.updateCampaign(campaignId, { [key]: payload }, token);
    } catch (error) {
      console.log(error);
    }
  };

  const updateReferralStatus = async (event, referralId) => {
    try {
      const token = await getAccessTokenSilently();
      const newStatus = event.target.value;
      const referralIndex = findIndex(campaign.referrals, ['id', referralId]);
      const updatedReferrals = [...campaign.referrals];

      await HiroApi.updateReferralStatus(campaign.id, referralId, { status: newStatus }, token);
      updatedReferrals[referralIndex].status = newStatus;
      setCampaign({ ...campaign, referrals: updatedReferrals });
      await getCampaignStatus();
    } catch (error) {
      console.log(error);
    }
  };

  const updateCampaignTag = async () => {
    try {
      const token = await getAccessTokenSilently();
      const tag = isEmpty(campaign.tag) ? 'saved' : '';

      await HiroApi.updateCampaign(campaignId, { tag }, token);
      setCampaign({
        ...campaign,
        tag,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getCampaignStatus = async () => {
    try {
      const token = await getAccessTokenSilently();
      const campaignStatus = await HiroApi.getCampaignStatus(campaignId, token);

      setCampaign({ ...campaign, status: campaignStatus });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUserSelect = async (userEmail) => {
    try {
      const token = await getAccessTokenSilently();

      await assignCampaign(campaignId, userEmail, token);
      setCampaign({ ...campaign, assignedTo: userEmail });
    } catch (error) {
      console.log(error);
    } finally {
      setAnchorEl(null);
    }
  };

  const handleCampaignInfoChange = (event) => {
    const { id, value } = event.target;

    setCampaign({ ...campaign, [id]: value });
    debouncedCampaignUpdate(id, value);
  };

  const handleProviderChange = (event) => {
    const pcpId = event.target.value;
    setCampaign({ ...campaign, pcp: { ...(campaign.pcp || {}), id: pcpId } });
    updateCampaign('pcpId', pcpId);
  };

  const debouncedCampaignUpdate = useCallback(
    debounce((key, value) => updateCampaign(key, value), DEBOUNCE_TIME),
    []
  );

  const displayProvider = () => {
    if (campaign?.pcp) {
      return campaign.pcp.id;
    }

    return null;
  };

  const isMinor = () => {
    if (campaign.isMinor) {
      return (
        <Tooltip
          title={
            <div
              style={{
                whiteSpace: 'pre-line',
                fontSize: '14px',
              }}
            >
              Patient is a minor
            </div>
          }
          placement="bottom"
          arrow
        >
          <InfoOutlinedIcon sx={{ color: 'orange' }} />
        </Tooltip>
      );
    }

    return null;
  };

  const addDate = () => {
    const today = dayjs().format('M/D/YY');
    let updatedNotes = '';

    if (campaign.notes.length > 0) {
      updatedNotes = `${campaign.notes}\n${today}: `;
    } else {
      updatedNotes = `${today}: `;
    }

    setCampaign({
      ...campaign,
      notes: updatedNotes,
    });
  };

  const formatAddress = (address) => {
    const addressParts = address.split(', ');
    const street = addressParts.slice(0, -3).join(', ');
    const cityStateZip = addressParts.slice(-3).join(', ');

    return (
      <div>
        <div>{street}</div>
        <div>{cityStateZip}</div>
      </div>
    );
  };

  const handleRatingChange = async (event, newValue) => {
    try {
      setRatingLoading(true);
      await updateCampaign('csatRating', newValue);
      setCampaign({ ...campaign, csatRating: newValue });
    } catch (error) {
      console.log(error);
    } finally {
      setRatingLoading(false);
    }
  };

  const handleCsatCommentsChange = (event) => {
    const { value } = event.target;

    setCampaign({ ...campaign, csatComments: value });
    debouncedCampaignUpdate('csatComments', value);
  };

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const displayDisqualifiers = (disqualifiers) => {
    return (
      <Tooltip
        title={
          <div
            style={{
              whiteSpace: 'pre-line',
              fontSize: '16px',
            }}
          >
            <b>Ingestion Flags:</b>
            <p />
            {disqualifiers.map((disqualifier) => (
              <div>{disqualifier}</div>
            ))}
          </div>
        }
        placement="bottom"
        arrow
      >
        <ErrorOutlineIcon className="warning" />
      </Tooltip>
    );
  };

  const displayReferral = (referral) => {
    const provider = getProviderInfo(referral.providerId) || {};
    const specialty = getSpecialtyInfo(referral.followupSpecialtyId) || {};

    return (
      <div key={referral.id} className="provider">
        <div className="provider-info">
          <div className="names">
            <div className="name">{provider.displayName}</div>
            <div className="specialty">{specialty.name}</div>
          </div>

          <div className="status">
            <FormControl size="small">
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                value={referral.status}
                onChange={(event) => updateReferralStatus(event, referral.id)}
                disabled={!referral.activated} // Disable when not activated
              >
                <ListSubheader>Closed</ListSubheader>
                <MenuItem value="HasProvider">Independent Provider</MenuItem>
                <MenuItem value="NoAction">No Action</MenuItem>
                <MenuItem value="NoResponse">No Response</MenuItem>
                <MenuItem value="BookBySMS">Booked - SMS</MenuItem>
                <MenuItem value="BookByPhone">Booked - Phone</MenuItem>
                <MenuItem value="ConsentRefused">Consent Refused</MenuItem>
                <ListSubheader>Ongoing</ListSubheader>
                <MenuItem value="ConsentPending">Consent Pending</MenuItem>
                <MenuItem value="SMSOngoing">Text Ongoing</MenuItem>
                <MenuItem value="ReadyForCall">Ready for Call</MenuItem>
                <MenuItem disabled value="Error">
                  Error
                </MenuItem>
                <MenuItem disabled value="ManualOverride">
                  Manual Override
                </MenuItem>
                <MenuItem value="OtherCall">Other Call</MenuItem>
                <ListSubheader>Not in Scope</ListSubheader>
                <MenuItem value="NoActiveNumber">No Active Number</MenuItem>
                <MenuItem value="HasExistingAppointment">Has Existing Visit</MenuItem>
                <MenuItem value="NotInScope">Other</MenuItem>
              </Select>
            </FormControl>
            {referral.disqualifiers.length ? displayDisqualifiers(referral.disqualifiers) : null}
          </div>
        </div>

        <div className="departments">
          {provider?.departments?.map((department) => (
            <div key={department.id} className="address">
              <div className="department">
                {department.name}
                <p>{department.phone}</p>
              </div>
              {formatAddress(department.address)}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return loading ? (
    <div className="container">
      <Loader />
    </div>
  ) : (
    <>
      <div className="campaign">
        <div className="campaign-header">
          <div className="patient-info">
            <UserAvatar user={getUser(campaign.assignedTo)} onClick={handleAvatarClick} />
            <div className="line" />
            <p>
              <b>Name:</b> {campaign.lastName}, {campaign.firstName}
            </p>
            <p>
              <b>Date of Birth:</b> {campaign.dob} {isMinor()}
            </p>
            <p>
              <b>EHR ID:</b> {campaign.ehrId}
            </p>
          </div>

          <div className="actions">
            <CampaignActions campaignId={campaignId} />

            {userType === USER_ROLES.ADMIN && (
              <Button variant="contained" onClick={() => setIsAuditOpen(!isAuditOpen)}>
                Audit
              </Button>
            )}

            <Button variant="contained" onClick={() => navigate(`/patients/${campaign.patientId}`)}>
              Profile
            </Button>

            {isEmpty(campaign.tag) ? (
              <Button className="tag empty" variant="contained" onClick={updateCampaignTag}>
                Flag
              </Button>
            ) : (
              <Button className="tag saved" variant="contained" onClick={updateCampaignTag}>
                Flagged
              </Button>
            )}
          </div>
        </div>

        <div className="section">
          <h3>Campaign</h3>

          <div className="info">
            <div className="input">
              <FormControl size="small">
                <InputLabel>PCP</InputLabel>
                <Select label="PCP" className="provider" value={displayProvider()} onChange={handleProviderChange}>
                  {providers?.map((provider) => (
                    <MenuItem key={provider.id} id={provider.id} value={provider.id}>
                      {provider.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="status">
              <TextField label="Status" size="small" value={mapEngagementStatus(campaign.status)} />
            </div>

            <div className="input">
              <TextField
                label="Discharge Date"
                size="small"
                value={formatDatetime(campaign.dischargeDate, facility.timezone)}
              />
            </div>
          </div>
        </div>

        <div className="section">
          <PhoneNumbers
            campaignId={campaignId}
            campaignNumber={campaign.phone}
            numbers={campaign.phoneNumbers}
            patientId={patientId}
            getCampaign={getCampaign}
          />
        </div>

        <div className="section">
          <ContactAttempts campaignId={campaignId} getCampaign={getCampaign} />
        </div>

        <div className="section">
          <div className="header">
            <h3>Discharge Details</h3>
          </div>

          <DischargeDetails campaign={campaign} />
        </div>

        <div className="section referred-providers">
          <h3>
            Referred Follow-up Providers
            <Button variant="text" className="providers-button" onClick={() => setOpenDrawer(true)}>
              View All Providers
            </Button>
          </h3>

          {campaign?.referrals?.map((referral) => displayReferral(referral))}
        </div>

        {campaign.referrals.length > 0 && (
          <>
            <PastPatientAppointments id={campaignId} type="campaign" />

            <PatientAppointments id={campaignId} type="campaign" />

            <Appointments campaign={campaign} getCampaign={getCampaign} />
          </>
        )}

        <div className="section notes">
          <div className="header">
            <h3>Campaign Notes</h3>
            <IconButton onClick={addDate}>
              <TodayIcon className="primary-color" />
            </IconButton>
          </div>
          <TextField id="notes" multiline rows={8} value={campaign.notes || ''} onChange={handleCampaignInfoChange} />
        </div>

        <div className="section csat">
          <div className="csat-rating">
            <Typography variant="subtitle2" color="text.secondary">
              CSAT Rating
            </Typography>
            <Rating
              value={campaign.csatRating}
              precision={1}
              max={5}
              size="large"
              onChange={handleRatingChange}
              disabled={ratingLoading}
            />
          </div>
          <TextField
            className="csat-comments"
            label="Comments"
            size="small"
            value={campaign.csatComments || ''}
            onChange={handleCsatCommentsChange}
          />
        </div>

        <MessageHistory
          campaign={campaign}
          getCampaignSilently={getCampaignSilently}
          setCampaign={setCampaign}
          phoneNumber={campaign.phone}
          referrals={campaign.referrals}
        />

        <ProvidersDrawer open={openDrawer} setOpenDrawer={setOpenDrawer} />
      </div>

      <BottomSheet isOpen={isAuditOpen}>
        <CampaignAudit campaignId={campaignId} isOpen={isAuditOpen} onClose={() => setIsAuditOpen(false)} />
      </BottomSheet>

      <UserSelect anchorEl={anchorEl} setAnchorEl={setAnchorEl} open={Boolean(anchorEl)} onClick={handleUserSelect} />
    </>
  );
}
