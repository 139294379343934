import './style.scss';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import UserAvatar from '../../components/UserAvatar/component';
import UserSelect from '../../components/UserSelect/component';
import { selectAssignee, setAssignee } from '../../state/systemSlice';
import { getUser } from '../../utils/helpers';

const noneUser = {
  id: 'none',
  firstName: 'None',
  lastName: '',
  picture: null,
  email: 'none',
};

export default function AssigneeFilter() {
  const dispatch = useDispatch();
  const assignee = useSelector(selectAssignee);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserSelect = (userEmail) => {
    dispatch(setAssignee(userEmail));
    setAnchorEl(null);
  };

  return (
    <div className="assignee-filter">
      <p>Assignee</p>
      {assignee === 'none' ? (
        <UserAvatar user={noneUser} onClick={handleAvatarClick} />
      ) : (
        <UserAvatar user={getUser(assignee)} onClick={handleAvatarClick} />
      )}
      <UserSelect anchorEl={anchorEl} setAnchorEl={setAnchorEl} open={Boolean(anchorEl)} onClick={handleUserSelect} />
    </div>
  );
}
