import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { debounce, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Loader from '../../components/Loader/Loader';
import HiroApi from '../../HiroApi';
import { selectCurrentFacility, selectProviders, showFacilitySelect } from '../../state/systemSlice';
import { DEBOUNCE_TIME } from '../../utils/constants/system';

export default function Settings() {
  const { getAccessTokenSilently } = useAuth0();
  const facility = useSelector(selectCurrentFacility);
  const showFacilitySelectModal = useSelector(showFacilitySelect);
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [inclusionName, setInclusionName] = useState('');
  const [exclusionName, setExclusionName] = useState('');
  const [providerId, setProviderId] = useState(''); // default until more are needed
  const [settings, setSettings] = useState({
    excludedProviders: [],
    inclusions: [],
  });
  const providers = useSelector(selectProviders);

  useEffect(() => {
    if (!isEmpty(facility) && !showFacilitySelectModal) {
      fetchSettings();
    }
  }, []);

  const fetchSettings = async () => {
    try {
      const token = await getAccessTokenSilently();
      const settings = await HiroApi.getSettings(token);

      setSettings(settings);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const excludeProvider = async () => {
    try {
      setActionLoading(true);
      const token = await getAccessTokenSilently();

      await HiroApi.excludeProvider(exclusionName, token);
      setExclusionName('');
      updateExcludedList();
      setActionLoading(false);
    } catch (error) {
      console.log(error);
      setActionLoading(false);
    }
  };

  const updateExcludedList = async () => {
    const updatedExcludedProviders = [...settings.excludedProviders];
    const last = settings.excludedProviders[settings.excludedProviders.length - 1];

    updatedExcludedProviders.push({ id: last.id + 1, name: exclusionName });

    setSettings({
      ...settings,
      excludedProviders: updatedExcludedProviders,
    });
  };

  const updateInclusionList = async () => {
    const updatedIncludedProviders = [...settings.inclusions];
    const last = settings.inclusions[settings.inclusions.length - 1];

    updatedIncludedProviders.push({ id: last.id + 1, name: inclusionName, providerId });

    setSettings({
      ...settings,
      inclusions: updatedIncludedProviders,
    });
  };

  const handleExcludedProviderChange = (event) => {
    const name = event.target.value;

    setExclusionName(name);
  };

  const addInclusion = async () => {
    try {
      setActionLoading(true);
      const token = await getAccessTokenSilently();

      await HiroApi.addInclusion(inclusionName, providerId, token);
      await updateInclusionList();
      setInclusionName('');
      setProviderId('');
      setActionLoading(false);
    } catch (error) {
      console.log(error);
      setActionLoading(false);
    }
  };

  const editInclusion = async (inclusionId, payload) => {
    try {
      const token = await getAccessTokenSilently();

      await HiroApi.editInclusion(inclusionId, payload, token);
    } catch (error) {
      console.log(error);
    }
  };

  const debouncedUpdate = useCallback(
    debounce((inclusionId, name) => editInclusion(inclusionId, { name }), DEBOUNCE_TIME),
    []
  );

  const editInclusionName = (event) => {
    const index = event.target.id;
    const newName = event.target.value;
    const inclusionsCopy = [...settings.inclusions];
    const editedRow = inclusionsCopy[index];

    editedRow.name = newName;
    setSettings({
      ...settings,
      inclusions: inclusionsCopy,
    });
    debouncedUpdate(editedRow.id, newName);
  };

  const removeInclusion = async (inclusionId) => {
    try {
      const token = await getAccessTokenSilently();

      await HiroApi.removeInclusion(inclusionId, token);
      await fetchSettings();
    } catch (error) {
      console.log(error);
    }
  };

  const updateProvider = (newProviderId, inclusionId) => {
    const inclusionsCopy = [...settings.inclusions];
    const editedRow = inclusionsCopy.find((row) => row.id === inclusionId);
    if (!editedRow) return;

    editedRow.providerId = newProviderId;
    setSettings({
      ...settings,
      inclusions: inclusionsCopy,
    });
    editInclusion(editedRow.id, { providerId: newProviderId });
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="settings">
      <h1>Settings</h1>

      <Paper elevation={3}>
        <h3>Inclusions</h3>

        <Table className="inclusions">
          <TableHead>
            <TableRow>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Provider</TableCell>
              <TableCell align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {settings?.inclusions.map((inclusion, index) => (
              <TableRow key={inclusion.id}>
                <TableCell align="center">
                  <TextField value={inclusion.name} onChange={editInclusionName} id={index.toString()} />
                </TableCell>
                <TableCell align="center">
                  <Select
                    value={inclusion.providerId}
                    onChange={(event) => updateProvider(event.target.value, inclusion.id)}
                  >
                    {providers.map((provider) => (
                      <MenuItem key={provider.id} value={provider.id}>
                        {provider.displayName}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell align="center">
                  <Button className="remove" onClick={() => removeInclusion(inclusion.id)}>
                    Remove
                  </Button>
                </TableCell>
              </TableRow>
            ))}

            <TableRow>
              <TableCell align="center">
                <TextField value={inclusionName} onChange={(event) => setInclusionName(event.target.value)} />
              </TableCell>
              <TableCell align="center">
                <FormControl>
                  <Select value={providerId} onChange={(event) => setProviderId(event.target.value)}>
                    {providers.map((provider) => (
                      <MenuItem key={provider.id} value={provider.id}>
                        {provider.displayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell align="center">
                <Button
                  className="add"
                  onClick={addInclusion}
                  disabled={actionLoading || isEmpty(inclusionName) || isEmpty(providerId)}
                >
                  Add
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>

      <Paper elevation={3}>
        <h3>Excluded Providers</h3>

        <ul className="list">
          {settings?.excludedProviders.map((provider) => (
            <li key={provider.id}>{provider.name}</li>
          ))}
        </ul>

        <div className="actions">
          <TextField value={exclusionName} onChange={handleExcludedProviderChange} />
          <Button className="add" onClick={excludeProvider} disabled={actionLoading || isEmpty(exclusionName)}>
            Exclude
          </Button>
        </div>
      </Paper>
    </div>
  );
}
