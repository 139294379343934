import './style.scss';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import ConfirmationModal from '../../components/ConfirmationModal/component';
import Loader from '../../components/Loader/Loader';
import { selectUploadFileFilters } from '../../state/systemSlice';
import { QUEUE_STATUS } from '../../utils/constants/upload';

export default function Actions(props) {
  const { actionLoading, selectedPatients, updateQueue } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const open = Boolean(anchorEl);
  const filters = useSelector(selectUploadFileFilters);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = async (queue) => {
    setAnchorEl(null);
    const patientsSelected = !isEmpty(selectedPatients);

    if (patientsSelected) {
      await updateQueue(queue);
    }
  };

  const showActions = (queue) => {
    if (filters.queue === 'all' || filters.queue !== queue) {
      return true;
    }

    return false;
  };

  const isReadyForCampaign = () => {
    if (areInSameQueue(QUEUE_STATUS.READY) && allAreReady()) {
      return true;
    }

    return false;
  };

  const areInSameQueue = (queue) => {
    if (selectedPatients.length === 0) {
      return false;
    }

    return selectedPatients.every((patient) => patient.queue === queue);
  };

  const allAreReady = () => {
    return selectedPatients.every((patient) => patient.queue === QUEUE_STATUS.READY);
  };

  return (
    <div className="actions">
      <Button variant="outlined" onClick={openMenu}>
        Actions
      </Button>

      <Menu className="table-filter-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {showActions('pending') ? <MenuItem onClick={() => handleClick('pending')}>Mark Pending</MenuItem> : null}
        {showActions('ready') ? <MenuItem onClick={() => handleClick('ready')}>Mark Ready</MenuItem> : null}
        {isReadyForCampaign() ? <MenuItem onClick={() => setModalOpen(true)}>Send Campaign</MenuItem> : null}
        {showActions('unfit') ? <MenuItem onClick={() => handleClick('unfit')}>Mark Unfit</MenuItem> : null}
      </Menu>

      {actionLoading ? <Loader size={35} /> : null}

      <ConfirmationModal
        confirmedAction={() => handleClick('sent')}
        open={modalOpen}
        setOpen={setModalOpen}
        message={`You are about to send ${selectedPatients.length} campaigns. Are you sure you want to proceed?`}
      />
    </div>
  );
}
