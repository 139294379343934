import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import HiroApi from '../../HiroApi';
import { formatDate, getSpecialtyInfo, mapEngagementStatus, mapReferralStatus } from '../../utils/helpers';

export default function Discharges({ discharges: dischargesFromProps, patientId }) {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [fetchedDischarges, setFetchedDischarges] = useState([]);

  const discharges = dischargesFromProps || fetchedDischarges;

  const fetchDischarges = async () => {
    const token = await getAccessTokenSilently();
    const discharges = await HiroApi.getPatientDischarges(patientId, token);

    setFetchedDischarges(discharges);
  };

  useEffect(() => {
    if (patientId && !dischargesFromProps) {
      fetchDischarges();
    }
  }, []);

  const goToCampaign = (campaignId) => {
    if (campaignId) {
      navigate(`/campaigns/${campaignId}`);
    }
  };

  const renderCampaignLink = (discharge) => {
    const campaignExists = discharge?.campaign?.id;

    if (campaignExists) {
      const className = campaignExists ? 'campaign-start' : 'campaign-inactive';

      return (
        <div className={className} onClick={() => goToCampaign(discharge.campaign.id)}>
          {formatDate(discharge.campaign.createdAt, true)}
        </div>
      );
    }

    return '';
  };

  const renderReferralStatuses = (discharge) => {
    if (!isEmpty(discharge?.campaign?.disqualifiers)) {
      return (
        <div className="referrals">
          {discharge.campaign.disqualifiers.map((disqualifier) => (
            <div key={disqualifier} className="referral">
              {disqualifier}
            </div>
          ))}
        </div>
      );
    }

    if (!isEmpty(discharge?.campaign?.referrals)) {
      return (
        <div className="referrals">
          {discharge.campaign.referrals.map((referral) => (
            <div key={referral.id} className="referral">
              {getSpecialtyInfo(referral.followupSpecialtyId)?.name}: {mapReferralStatus(referral.status)}
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <div className="discharges section">
      <h3>Discharges</h3>

      {!isEmpty(discharges) ? (
        <TableContainer>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center">Discharge Date</TableCell>
                <TableCell align="center">Campaign Start Date</TableCell>
                <TableCell align="center">Campaign Status</TableCell>
                <TableCell align="center">Referral Status</TableCell>
                <TableCell align="center">Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {discharges.map((discharge) => (
                <TableRow key={discharge.id}>
                  <TableCell align="center">{formatDate(discharge.dateTime, true)}</TableCell>
                  <TableCell align="center">{renderCampaignLink(discharge)}</TableCell>
                  <TableCell align="center">
                    {discharge?.campaign ? mapEngagementStatus(discharge.campaign.status) : ''}
                  </TableCell>
                  <TableCell align="center">{renderReferralStatuses(discharge)}</TableCell>
                  <TableCell align="center">
                    <Tooltip
                      title={
                        <div
                          style={{
                            whiteSpace: 'pre-line',
                            fontSize: '14px',
                          }}
                        >
                          {discharge?.campaign?.notes}
                        </div>
                      }
                      placement="bottom"
                      arrow
                    >
                      <TextSnippetOutlinedIcon
                        sx={{
                          color: discharge?.campaign && discharge.campaign.notes?.length ? 'black' : 'gray',
                        }}
                      />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        'Patient does not have any discharges.'
      )}
    </div>
  );
}
