import './style.scss';

import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Fab from '@mui/material/Fab';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { selectActiveWidget, selectCurrentFacility, setActiveWidget } from '../../state/systemSlice';
import { selectStatus } from '../../state/twilioSlice';
import { FACILITIES } from '../../utils/constants/system';
import { DEVICE_STATUS } from '../../utils/constants/twilio';
import PatientWizard from '../PatientWizard/PatientWizard';
import Twilio from '../Twilio/component';

export default function Widgets() {
  const dispatch = useDispatch();
  const location = useLocation();
  const facility = useSelector(selectCurrentFacility);
  const twilioDeviceStatus = useSelector(selectStatus);
  const activeWidget = useSelector(selectActiveWidget);

  const toggleWidget = (widgetId) => {
    dispatch(setActiveWidget(activeWidget === widgetId ? null : widgetId));
  };

  return (
    <div className="widgets">
      <div className="fabs">
        {location.pathname.includes('/campaigns') && (
          <Fab size="medium" onClick={() => toggleWidget('wizard')}>
            <QuestionMarkIcon />
          </Fab>
        )}

        {facility.id !== FACILITIES.FRYE ? (
          <Fab
            className={twilioDeviceStatus === DEVICE_STATUS.INCOMING.label ? 'pulse-animation' : ''}
            size="medium"
            onClick={() => toggleWidget('twilio')}
          >
            <LocalPhoneOutlinedIcon />
          </Fab>
        ) : null}
      </div>

      <div className="components">
        <PatientWizard isOpen={activeWidget === 'wizard'} />
        {facility.id !== FACILITIES.FRYE ? <Twilio isOpen={activeWidget === 'twilio'} /> : null}
      </div>
    </div>
  );
}
