import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallMergeIcon from '@mui/icons-material/CallMerge';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import ComputerIcon from '@mui/icons-material/Computer';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PersonIcon from '@mui/icons-material/Person';
import RefreshIcon from '@mui/icons-material/Refresh';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AudioPlayer from '../../components/AudioPlayer/component';
import DateTimeInput from '../../components/DateTimeInput/component';
import Loader from '../../components/Loader/Loader';
import LoadingButton from '../../components/LoadingButton/component';
import HiroApi from '../../HiroApi';
import {
  fetchContactAttempts,
  selectContactAttempts,
  selectContactAttemptsLoading,
  setContactAttempts,
} from '../../state/campaignSlice';
import { selectUserType } from '../../state/userSlice';
import { CONVERSATION_ACTOR, USER_ROLES } from '../../utils/constants/system';
import { CALL_DIRECTION } from '../../utils/constants/twilio';
import { formatDatetime, formatInternationalPhone } from '../../utils/helpers';

export default function ContactAttempts({ campaignId, getCampaign }) {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const userType = useSelector(selectUserType);
  const attempts = useSelector(selectContactAttempts);
  const loading = useSelector(selectContactAttemptsLoading);
  const [unattributedCampaigns, setUnattributedCampaigns] = useState([]);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [importLoading, setImportLoading] = useState(false);

  useEffect(() => {
    if (campaignId) {
      getContactAttempts();
    }
  }, [campaignId]);

  const getContactAttempts = async () => {
    const token = await getAccessTokenSilently();

    dispatch(fetchContactAttempts(campaignId, token));
  };

  const addContactAttempt = async () => {
    try {
      const token = await getAccessTokenSilently();
      const newAttempt = await HiroApi.addContactAttempt(campaignId, { from: CONVERSATION_ACTOR.COORDINATOR }, token);

      dispatch(setContactAttempts([...attempts, newAttempt]));
    } catch (error) {
      console.log(error);
    }
  };

  const deleteContactAttempt = async (attemptId, index) => {
    try {
      const token = await getAccessTokenSilently();
      const filtered = [...attempts.slice(0, index), ...attempts.slice(index + 1)];

      await HiroApi.deleteContactAttempt(campaignId, attemptId, token);
      dispatch(setContactAttempts(filtered));
    } catch (error) {
      console.log(error);
    }
  };

  const updateContactAttempt = async (attempt) => {
    try {
      const token = await getAccessTokenSilently();

      await HiroApi.updateContactAttempt(campaignId, attempt.id, attempt, token);
    } catch (error) {
      console.log(error);
    }
  };

  const getUnattributedCampaigns = async () => {
    try {
      const token = await getAccessTokenSilently();
      const campaigns = await HiroApi.getUnattributedCampaigns(token);

      setUnattributedCampaigns(campaigns);
    } catch (error) {
      console.log(error);
    }
  };

  const openImportModal = async () => {
    await getUnattributedCampaigns();
    setImportModalOpen(true);
  };

  const handleImport = async (campaignToImport) => {
    try {
      setImportLoading(true);
      const token = await getAccessTokenSilently();

      await HiroApi.importCampaign(campaignId, campaignToImport, token);
      await getCampaign(campaignId, token);
      await getContactAttempts();
    } catch (error) {
      console.log(error);
    } finally {
      setImportLoading(false);
      setImportModalOpen(false);
    }
  };

  const handleContactTypeChange = async (event, index) => {
    const type = event.target.value;
    const attemptsCopy = attempts.map((attempt, i) => {
      if (i === index) {
        return { ...attempt, type };
      }

      return attempt;
    });

    dispatch(setContactAttempts(attemptsCopy));
    await updateContactAttempt({ ...attempts[index], type });
  };

  const handleContactEngagementChange = async (event, index) => {
    const engagement = event.target.value;
    const attemptsCopy = attempts.map((attempt, i) => {
      if (i === index) {
        return { ...attempt, engagement };
      }

      return attempt;
    });

    dispatch(setContactAttempts(attemptsCopy));
    await updateContactAttempt({ ...attempts[index], engagement });
  };

  const handleContactTimeChange = async (value, index) => {
    const timestamp = dayjs(value).utc().format();
    const attemptsCopy = attempts.map((attempt, i) => {
      if (i === index) {
        return { ...attempt, timestamp };
      }

      return attempt;
    });

    dispatch(setContactAttempts(attemptsCopy));
    await updateContactAttempt({ ...attempts[index], timestamp });
  };

  const isLastAttempt = (attempts, index) => {
    return index === attempts.length - 1;
  };

  const showRecording = (attempt) => {
    if (isEmpty(attempt.call?.recordings)) {
      return false;
    }

    if (userType === USER_ROLES.ADMIN) {
      return true;
    }

    return attempt.call?.recordings[0]?.source === 'RecordVerb';
  };

  const updateRecordingReadStatus = (attempt, index) => {
    const attemptsCopy = attempts.map((a, i) => {
      if (i === index) {
        const updatedAttempt = { ...attempt };

        if (updatedAttempt.call?.recordings?.[0]) {
          return {
            ...updatedAttempt,
            call: {
              ...updatedAttempt.call,
              recordings: [
                {
                  ...updatedAttempt.call.recordings[0],
                  isRead: true,
                },
                ...updatedAttempt.call.recordings.slice(1),
              ],
            },
          };
        }

        return updatedAttempt;
      }

      return a;
    });

    dispatch(setContactAttempts(attemptsCopy));
  };

  const renderDirectionIcon = (attempt) => {
    if (attempt?.call?.direction === CALL_DIRECTION.OUTGOING || attempt?.type === 'SMS') {
      return <CallMadeIcon sx={{ cursor: 'pointer' }} />;
    }

    if (attempt?.call?.direction === CALL_DIRECTION.INCOMING) {
      return <CallReceivedIcon sx={{ cursor: 'pointer' }} />;
    }

    return <span />;
  };

  const displayAttempts = () => {
    if (attempts.length) {
      return attempts.map((attempt, index, attempts) => (
        <div key={attempt.id} className="attempt">
          {attempt.from === CONVERSATION_ACTOR.COORDINATOR ? <PersonIcon /> : <ComputerIcon />}

          {attempt.type === 'SMS' ? (
            renderDirectionIcon(attempt)
          ) : (
            <Tooltip
              title={`From: ${formatInternationalPhone(attempt?.call?.from)} \n To: ${formatInternationalPhone(
                attempt?.call?.to
              )}`}
            >
              {renderDirectionIcon(attempt)}
            </Tooltip>
          )}

          <FormControl>
            <Select
              className="type"
              label="Type"
              value={attempt.type}
              onChange={(event) => handleContactTypeChange(event, index)}
              disabled={attempt.from === CONVERSATION_ACTOR.SYSTEM || attempt.from === CONVERSATION_ACTOR.PATIENT}
            >
              <MenuItem value="Call">Call</MenuItem>
              <MenuItem value="SMS">SMS</MenuItem>
            </Select>
          </FormControl>

          <FormControl>
            <Select
              className="type"
              label="Engagement"
              value={attempt.engagement}
              onChange={(event) => handleContactEngagementChange(event, index)}
            >
              <MenuItem value>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>

          <DateTimeInput
            disabled={attempt.from === CONVERSATION_ACTOR.SYSTEM || attempt.from === CONVERSATION_ACTOR.PATIENT}
            label="Date/Time"
            value={attempt.timestamp}
            index={index}
            onChange={handleContactTimeChange}
          />

          <div className="actions">
            {attempt.from === CONVERSATION_ACTOR.COORDINATOR ? (
              <IconButton onClick={() => deleteContactAttempt(attempt.id, index)}>
                <DeleteOutlineIcon className="add" />
              </IconButton>
            ) : null}

            {showRecording(attempt) ? (
              <AudioPlayer
                attempt={attempt}
                index={index}
                recording={attempt?.call?.recordings[0]}
                updateRecordingReadStatus={updateRecordingReadStatus}
              />
            ) : null}

            {isLastAttempt(attempts, index) ? (
              <IconButton onClick={addContactAttempt}>
                <AddBoxOutlinedIcon className="add" />
              </IconButton>
            ) : null}
          </div>
        </div>
      ));
    }

    return (
      <IconButton onClick={addContactAttempt}>
        <AddBoxOutlinedIcon className="add" />
      </IconButton>
    );
  };

  const displayCampaign = (campaign) => {
    return (
      <TableRow>
        <TableCell>{campaign.id}</TableCell>
        <TableCell>{campaign.phone}</TableCell>
        <TableCell>{formatDatetime(campaign.createdAt)}</TableCell>
        <TableCell>
          <LoadingButton
            variant="outlined"
            label="Import"
            isLoading={importLoading}
            onClick={() => handleImport(campaign.id)}
          />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div className="contact-attempts">
      <div className="header">
        <h3>Contact Attempts</h3>

        {userType === USER_ROLES.ADMIN ? (
          <Tooltip title="Import from campaign">
            <IconButton onClick={openImportModal}>
              <CallMergeIcon sx={{ transform: 'rotate(90deg)' }} />
            </IconButton>
          </Tooltip>
        ) : null}

        {loading ? (
          <Loader size={35} />
        ) : (
          <IconButton onClick={getContactAttempts}>
            <RefreshIcon />
          </IconButton>
        )}
      </div>

      {loading ? null : displayAttempts()}

      <Dialog
        className="import-modal"
        maxWidth={false}
        open={importModalOpen}
        onClose={() => !importLoading && setImportModalOpen(false)}
        disableEscapeKeyDown={importLoading}
        disableBackdropClick={importLoading}
      >
        <DialogTitle>Import from Campaign</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Campaign ID</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Campaign Created</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>{unattributedCampaigns.map((campaign) => displayCampaign(campaign))}</TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
}
