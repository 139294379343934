import './style.scss';

import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';
import { isEmpty } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectAllUsers } from '../../state/systemSlice';
import UserAvatar from '../UserAvatar/component';

export default function UserSelect({ anchorEl, setAnchorEl, onClick, open = false }) {
  const users = useSelector(selectAllUsers);

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (isEmpty(users)) {
    return (
      <Popover className="user-select" open={open} onClose={handleClose} anchorEl={anchorEl}>
        <MenuItem onClick={handleClose}>No users available</MenuItem>
      </Popover>
    );
  }

  return (
    <Popover
      className="user-select"
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      <MenuList>
        <MenuItem key="none" className="none" onClick={() => onClick(null)}>
          None
        </MenuItem>
        {users.map((user) => (
          <MenuItem key={user.id} onClick={() => onClick(user.email)}>
            <UserAvatar user={user} />
            <div className="user-info">
              <h5>
                {user.firstName} {user.lastName}
              </h5>
            </div>
          </MenuItem>
        ))}
      </MenuList>
    </Popover>
  );
}
