import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import Loader from '../../components/Loader/Loader';
import HiroApi from '../../HiroApi';
import { selectCurrentFacility, selectUploadFilters, setUploadFilters } from '../../state/systemSlice';
import { selectUserType } from '../../state/userSlice';
import { USER_ROLES } from '../../utils/constants/system';

export default function UploadList() {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const facility = useSelector(selectCurrentFacility);
  const userType = useSelector(selectUserType);
  const uploadFilters = useSelector(selectUploadFilters);
  const inputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [uploads, setUploads] = useState([]);
  const [error, setError] = useState({});

  useEffect(() => {
    if (userType !== USER_ROLES.ADMIN && !isEmpty(facility)) {
      navigate('/dashboard');
    }

    if (!isEmpty(facility)) {
      getUploads(uploadFilters.startDate, uploadFilters.endDate);
    }
  }, [uploadFilters]);

  const getUploads = async (startDate, endDate) => {
    try {
      setLoading(true);

      const token = await getAccessTokenSilently();
      const uploads = await HiroApi.getUploads({ startDate, endDate }, token);

      setUploads(uploads);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const openFileDialog = () => {
    inputRef.current.click();
  };

  const handleFileChange = (event) => {
    const fileObjs = event.target.files;

    if (!fileObjs) return;

    const newFiles = Array.from(fileObjs).filter((fileObj) => {
      const isDuplicate = files.some((existingFile) => existingFile.name === fileObj.name);

      if (isDuplicate) {
        setError({ message: `File "${fileObj.name}" already added` });
        return false;
      }
      return true;
    });

    setFiles((prev) => [...prev, ...newFiles]);
    event.target.value = '';
  };

  const uploadFile = async () => {
    setLoading(true);

    try {
      const token = await getAccessTokenSilently();
      const formData = new FormData();

      files.forEach((file) => {
        formData.append('files', file, file.name);
      });

      await HiroApi.uploadPatients(formData, token);
      setFiles([]);
      await getUploads(uploadFilters.startDate, uploadFilters.endDate);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="upload">
      {!isEmpty(error) ? (
        <Alert
          severity="error"
          onClose={() => {
            setError({});
          }}
        >
          {error.statusCode ? `[${error.statusCode}] ` : null} {error.message}
        </Alert>
      ) : null}

      <h1>Discharges</h1>

      <div className="upload-header">
        <div className="file-upload">
          <IconButton onClick={openFileDialog}>
            <AddCircleOutlineIcon />
            <input ref={inputRef} type="file" onChange={handleFileChange} multiple />
            <span>Upload Files</span>
          </IconButton>
        </div>

        <div className="date-filters">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={dayjs(uploadFilters.startDate)}
              onChange={(date) => {
                dispatch(setUploadFilters({ startDate: date.format('MM/DD/YYYY') }));
              }}
            />
            <DatePicker
              label="End Date"
              value={dayjs(uploadFilters.endDate)}
              onChange={(date) => {
                dispatch(setUploadFilters({ endDate: date.format('MM/DD/YYYY') }));
              }}
            />
          </LocalizationProvider>
        </div>
      </div>

      {files.length > 0 && (
        <div className="file-info">
          {files.map((file, index) => (
            <Chip
              key={file.name}
              label={file.name}
              onDelete={() => setFiles((prev) => prev.filter((_, i) => i !== index))}
              size="small"
              variant="outlined"
            />
          ))}
          <Button variant="contained" className="button" onClick={uploadFile}>
            Upload {files.length} {files.length === 1 ? 'File' : 'Files'}
          </Button>
        </div>
      )}

      {loading ? (
        <Loader />
      ) : (
        <div className="upload-content">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Filename</TableCell>
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="center">Ready</TableCell>
                  <TableCell align="center">Pending</TableCell>
                  <TableCell align="center">Sent</TableCell>
                  <TableCell align="center">Not Fit</TableCell>
                  <TableCell align="center">Total Discharges</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {uploads.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell align="center">
                      <Link to={`/patients/upload/${row.id}`}>{row.name}</Link>
                    </TableCell>
                    <TableCell align="center">{row.date}</TableCell>
                    <TableCell align="center">{row.ready}</TableCell>
                    <TableCell align="center">{row.pending}</TableCell>
                    <TableCell align="center">{row.sent}</TableCell>
                    <TableCell align="center">{row.unfit}</TableCell>
                    <TableCell align="center">{row.ready + row.sent + row.pending + row.unfit}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
}
