import { isEmpty } from 'lodash';

import HiroApi from '../HiroApi';
import store from '../state/store';
import { setCampaignActions, setDepartments, setProviders, setSpecialties, setUsers } from '../state/systemSlice';

export const fetchUsers = async (token) => {
  const users = await HiroApi.getUsers(token);

  store.dispatch(setUsers(users));
};

export const fetchProviders = async (token) => {
  const state = store.getState();
  const providersInStore = state.system.providers;

  if (isEmpty(providersInStore)) {
    const providers = await HiroApi.getProviders(null, null, token);

    store.dispatch(setProviders(providers));
  }
};

export const fetchSpecialties = async (token) => {
  const state = store.getState();
  const specialtiesInStore = state.system.specialties;

  if (isEmpty(specialtiesInStore)) {
    const specialties = await HiroApi.getSpecialties(token);

    store.dispatch(setSpecialties(specialties));
  }
};

export const fetchDepartments = async (specialtyId, token) => {
  try {
    const departments = await HiroApi.getDepartments(specialtyId, token);

    store.dispatch(setDepartments(departments));
  } catch (error) {
    console.log(error);
  }
};

export const fetchCampaignActions = async (token) => {
  const actions = await HiroApi.getActionOptions(token);

  store.dispatch(setCampaignActions(actions));
};

export const translate = async (payload, token) => {
  try {
    return await HiroApi.translateMessage(payload, token);
  } catch (error) {
    return error;
  }
};

export const assignCampaign = async (campaignId, userEmail, token) => {
  try {
    const assignee = [{ assignedTo: userEmail, campaignId }];

    return await HiroApi.assignCampaign(assignee, token);
  } catch (error) {
    return error;
  }
};
