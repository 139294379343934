import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

export default function ExtractionRecommendation({ extraction }) {
  return (
    <div className="extraction-recommendation">
      <h3 className="extraction-recommendation-header">Extraction Recommendation</h3>
      <div className="extraction-recommendation-body">{extraction?.evidence}</div>
      <div className="extraction-recommendation-footer">
        <div className="extraction-recommendation-footer-left">
          {extraction?.providerName && (
            <div className="provider-name">
              <span>Provider:</span> {extraction?.providerName}
            </div>
          )}
          {extraction?.followupSpecialty && (
            <div className="followup-specialty">
              <span>Followup Specialty:</span> {extraction?.followupSpecialty}
            </div>
          )}
        </div>
        <div className="extraction-recommendation-footer-right">
          <Tooltip title={`Reason:\n\n${extraction?.reason}`}>
            <InfoOutlinedIcon />
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
