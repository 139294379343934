import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../components/Loader/Loader';
import HiroApi from '../../HiroApi';
import { setCampaigns } from '../../state/campaignSlice';
import {
  resetFilters,
  selectAllFilters,
  selectCurrentFacility,
  selectEndDate,
  selectStartDate,
  setEndDate,
  setStartDate,
  showFacilitySelect,
} from '../../state/systemSlice';
import AssigneeFilter from './AssigneeFilter';
import NeedsActionFilter from './NeedsActionFilter';
import QueueFilter from './QueueFilter';
import ReferralStatusFilter from './ReferralStatusFilter';
import SearchFilter from './SearchFilter';
import SpecialtyFilter from './SpecialtyFilter';

export default function TableFilters(props) {
  const { loading, setLoading } = props;
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const facility = useSelector(selectCurrentFacility);
  const showFacilitySelectModal = useSelector(showFacilitySelect);
  const filters = useSelector(selectAllFilters);
  const startDate = useSelector(selectStartDate);
  const endDate = useSelector(selectEndDate);

  useEffect(() => {
    if (!showFacilitySelectModal && !isEmpty(facility)) {
      fetchCampaigns();
    }
  }, [
    filters.providers,
    filters.startDate,
    filters.endDate,
    filters.queue,
    filters.specialty,
    filters.referralStatus,
    filters.needsAction,
    filters.assignee,
  ]);

  const fetchCampaigns = async () => {
    setLoading(true);

    try {
      const token = await getAccessTokenSilently();
      const campaigns = await HiroApi.getCampaigns(filters, token);

      dispatch(setCampaigns(campaigns));
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const refreshTable = async () => {
    await fetchCampaigns();
  };

  const resetTableFilters = () => {
    dispatch(resetFilters());
  };

  return (
    <div className="filters">
      <QueueFilter />
      <div className="date-filters">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start Date"
            value={dayjs(startDate)}
            onChange={(date) => {
              dispatch(setStartDate(date.format('MM/DD/YYYY')));
            }}
          />
          <DatePicker
            label="End Date"
            value={dayjs(endDate)}
            onChange={(date) => {
              dispatch(setEndDate(date.format('MM/DD/YYYY')));
            }}
          />
        </LocalizationProvider>
      </div>
      <NeedsActionFilter />
      <SpecialtyFilter />
      <ReferralStatusFilter />
      <SearchFilter fetchCampaigns={fetchCampaigns} />
      <AssigneeFilter />
      <IconButton onClick={resetTableFilters}>
        <FilterListOffIcon />
      </IconButton>
      <div className="refresh">
        {loading ? (
          <Loader />
        ) : (
          <IconButton onClick={refreshTable}>
            <RefreshIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
}
