import './style.scss';

import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import { isEmpty } from 'lodash';
import React from 'react';

export default function UserAvatar({ onClick, user }) {
  if (!isEmpty(user)) {
    return (
      <Tooltip title={`${user?.firstName} ${user?.lastName}`}>
        <Avatar className="user-avatar" src={user?.picture} onClick={onClick} />
      </Tooltip>
    );
  }

  return (
    <Avatar className="user-avatar unassigned" onClick={onClick}>
      <PersonAddOutlinedIcon fontSize="small" />
    </Avatar>
  );
}
