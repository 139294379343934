import './style.scss';

import React from 'react';

export default function Provider(props) {
  const { provider, specialty } = props;

  return (
    <div className="provider">
      <div className="provider-header">
        <div className="names">
          <div className="name">{provider.displayName}</div>
          <div className="specialty">{specialty.name}</div>
        </div>
        <div className="provider-details">
          <div className="gender">
            <b>Gender:</b> {provider.gender}
          </div>
          <div className="languages">
            <b>Languages:</b> {provider?.languages?.join(', ')}
          </div>
        </div>
      </div>

      {provider.departments.map((department) => (
        <div key={department.id} className="address">
          <div className="name">{department.name}</div>
          <div>{department.address}</div>
          <div>{department.phone}</div>
        </div>
      ))}
    </div>
  );
}
