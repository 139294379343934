import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../components/Loader/Loader';
import UserSelect from '../../components/UserSelect/component';
import { selectCampaigns, setCampaigns } from '../../state/campaignSlice';
import { selectQueue } from '../../state/systemSlice';
import { selectUserType } from '../../state/userSlice';
import { USER_ROLES } from '../../utils/constants/system';
import { assignCampaign } from '../../utils/helpers-api';
import CollapsibleRow from './CollapsibleRow';

export default function CampaignsTable(props) {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const { loading } = props;
  const campaigns = useSelector(selectCampaigns);
  const userType = useSelector(selectUserType);
  const queue = useSelector(selectQueue);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const assignToCampaign = async (userEmail) => {
    try {
      const token = await getAccessTokenSilently();

      await assignCampaign(selectedCampaign, userEmail, token);
      updateCampaigns(selectedCampaign, userEmail);
      setAnchorEl(null);
    } catch (error) {
      console.error(error);
    }
  };

  const updateCampaigns = async (campaignId, userEmail) => {
    const updatedCampaigns = campaigns.map((campaign) =>
      campaign.id === campaignId ? { ...campaign, assignedTo: userEmail } : campaign
    );

    dispatch(setCampaigns(updatedCampaigns));
  };

  const handleAvatarClick = (event, campaignId) => {
    setSelectedCampaign(campaignId);
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className="patient-table">
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className="tag" />
              <TableCell align="center">Assignee</TableCell>
              <TableCell align="center">Patient Name</TableCell>
              <TableCell align="center">Discharge Date</TableCell>
              <TableCell align="center">Campaign Start</TableCell>
              <TableCell align="center">First Contact</TableCell>
              {queue === 'ReadyForCall' ? <TableCell align="center">Last Contact</TableCell> : null}
              <TableCell align="center">PCP</TableCell>
              <TableCell align="center">Referral Status</TableCell>
              {userType === USER_ROLES.ADMIN ? <TableCell align="center">Consent</TableCell> : null}
              <TableCell align="center">Action</TableCell>
              <TableCell align="center">Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell sx={{ borderBottom: 'none' }}>
                  <Loader />
                </TableCell>
              </TableRow>
            ) : (
              campaigns.map((campaign, index) => (
                <CollapsibleRow
                  key={campaign.id}
                  index={index}
                  campaign={campaign}
                  campaigns={campaigns}
                  handleAvatarClick={handleAvatarClick}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <UserSelect anchorEl={anchorEl} setAnchorEl={setAnchorEl} open={Boolean(anchorEl)} onClick={assignToCampaign} />
    </div>
  );
}
