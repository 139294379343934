import './style.scss';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectNeedsAction, selectQueue, setNeedsAction, setQueue } from '../../state/systemSlice';
import { selectUserType } from '../../state/userSlice';
import { NEEDS_ACTIONS_FILTERS } from '../../utils/constants/campaign';
import { USER_ROLES } from '../../utils/constants/system';

export default function NeedsActionFilter() {
  const dispatch = useDispatch();
  const queue = useSelector(selectQueue);
  const selectedNeedsAction = useSelector(selectNeedsAction);
  const userRole = useSelector(selectUserType);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    const action = event.currentTarget.getAttribute('data-action');

    if (selectedNeedsAction.includes(action)) {
      dispatch(setNeedsAction(selectedNeedsAction.filter((item) => item !== action)));
    } else {
      if (queue !== 'NeedsAction') {
        dispatch(setQueue({ queue: 'NeedsAction' }));
      }

      dispatch(setNeedsAction([...selectedNeedsAction, action]));
    }
  };

  const isChecked = (action) => {
    return selectedNeedsAction.includes(action.value);
  };

  return (
    <div className="table-filter needs-action-filter">
      <Button variant="outlined" onClick={openMenu}>
        Needs Action
        {selectedNeedsAction.length ? <p>({selectedNeedsAction.length})</p> : null}
      </Button>
      <Menu className="table-filter-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {NEEDS_ACTIONS_FILTERS.map((action) => {
          if (userRole !== USER_ROLES.ADMIN && (action.value === 'AgentReview' || action.value === 'PatientMessage')) {
            return null;
          }

          return (
            <MenuItem key={action.value} data-action={action.value} onClick={handleChange}>
              <Checkbox checked={isChecked(action)} />
              {action.label}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
